'use client';
import * as React from 'react';
import { useFeatureFlag } from '@dreamplan/shared-ui/client/feature-flag';
import dynamic from 'next/dynamic';
import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';

const ChatDisabledPaths = ['signup', 'expert-view', 'chatflow', 'eskatdata'];

const DeferedSmallChat = dynamic(() =>
  import('components/SmallChat/DeferedSmallChat').then((mod) => mod.DeferedSmallChat),
);

export function Scripts() {
  const { isFeatureFlagEnabled } = useFeatureFlag('CookieConsent');
  const [isEnabled, setEnabled] = React.useState(false);
  const pathname = usePathname();
  const paths = pathname.split('/').filter((i) => i);
  const params = useSearchParams();
  let isPrintMode = params.get('print') === 'true';

  React.useEffect(() => {
    const EnableChat = !paths.some((item) => ChatDisabledPaths.includes(item));
    setEnabled(EnableChat);
  }, [pathname]);

  return (
    <>
      {isFeatureFlagEnabled && !isPrintMode && (
        <Script
          id="CookieConsent"
          src="https://policy.app.cookieinformation.com/uc.js"
          data-culture="DA"
          type="text/javascript"
        ></Script>
      )}
      {isEnabled && !isPrintMode && <DeferedSmallChat />}
    </>
  );
}
